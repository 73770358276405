export const env = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return "https://odap.cafe24.com";
      break;
    case 'development':
      return "http://dev.5dap.cards:9000";
      break;
    default:
      return "http://localhost";
  }
}
